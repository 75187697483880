<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-card
        elevation="5"
        :width="params.width"
        :height="params.height"
        class="text-center d-flex flex-column ma-2"
        v-bind="attrs"
        v-on="on"
      >
        <v-card-title
          :style="{ lineHeight: params.headerSize, backgroundColor: statColor }"
          class="white--text d-block text-center text-no-wrap text-truncate pa-1 text-subtitle-1"
        >
          <span :style="{ fontSize: params.headerSize }">{{ header }}</span>
        </v-card-title>
        <v-card-text
          class="d-flex flex-grow-1 justify-center align-center pa-0"
        >
          <v-icon :color="statColor" :size="params.iconSize">{{
            icons[(icon || "").toLowerCase()] || icon
          }}</v-icon>
        </v-card-text>

        <v-card-actions
          class="d-block text-center text-no-wrap text-truncate text-caption pa-0 pl-2 pr-2"
        >
          <span :style="{ fontSize: params.footerSize, color: statColor }">{{
            footer
          }}</span>
        </v-card-actions>
      </v-card>
    </template>
    <span>{{ tooltip || footer }}</span>
  </v-tooltip>
</template>

<script>
import icons from "../utils/icons";
export default {
  name: "StatCard",
  data() {
    return {
      icons,
    };
  },
  computed: {
    statColor() {
      return this.color === undefined ? "#173042" : this.color;
    },
    params() {
      return this.small === undefined
        ? {
            width: 100,
            height: 100,
            iconSize: 36,
            headerSize: "14px",
            footerSize: "10px",
          }
        : {
            width: 75,
            height: 75,
            iconSize: 24,
            headerSize: "10px",
            footerSize: "10px",
          };
    },
  },
  props: ["icon", "header", "footer", "small", "color", "tooltip"],
};
</script>
