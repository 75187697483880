<template>
  <v-card>
    <v-toolbar color="primary" dark>
      <v-toolbar-title>Mobile Device Detected</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <!-- {{ error }}
      {{ saved }}
      //-------------------IOS 10.5 >= OK
      //-------------------Android 4.4 >= OK
      {{ device2 }} -->
      <!-- {{ user.email }} -->
      <div class="d-flex justify-center">
        <StatCard
          header="Device"
          :icon="device.platformType"
          :footer="device.model || header"
        />
        <StatCard
          header="OS"
          :icon="device.osName"
          :footer="`${device.osName} ${device.osVersionMajor}`"
        />
        <StatCard
          header="Browser"
          icon="application"
          :footer="`${device.browserName} ${device.browserVersionMajor}`"
        />
      </div>
      <v-alert type="error" v-if="error">
        <p>
          Something went wrong. We have reported the error to support. In the
          meantime, please return to Verify on your computer to complete the
          process.
        </p>
        <p>Error message: {{ error.toString() }}</p>
      </v-alert>
      <template v-else>
        <p>
          Great! We have registered this device with the Verify site. If you
          have already started this process on you home office computer, please
          return to that computer now and complete that process there.
        </p>
        <p>
          If you are just starting this process, you need to load this page on
          your home office computer and complete the assessment there.
        </p>
        <p>
          You can send this page to your home office computer by sharing the
          link with yourself. To do so, click
          <v-btn color="primary" dark small @click="share">HERE </v-btn>
        </p>
      </template>
      <!-- <pre>{{ device }}</pre> -->
    </v-card-text>
  </v-card>
</template>

<script>
import saveDoc from "../utils/saveDoc";
import device from "../utils/device";
import getISP from "../utils/getISP";
import StatCard from "../components/StatCard";
import dayjs from "dayjs";
import { mapState } from "vuex";
let devicePixelRatio = +window.devicePixelRatio.toFixed(2);

export default {
  data() {
    return {
      sharable: navigator.share ? true : false,
      isp: null,
      error: null,
      saved: false,
    };
  },
  components: {
    StatCard,
  },
  computed: {
    ...mapState(["user"]),
    device() {
      let result = {
        id: `${this.user?.email||'unknown'}-${device?.os?.name || ''}-${device?.os?.version||''}`,
        uid: this.user?.uid||null,
        email: this.user?.email || null,
        vendor: device?.device?.vendor || null,
        model: device?.device?.model || null,
        type: device?.device?.type || null,
        browserName: device?.browser?.name || null,
        browserVersion: device?.browser?.version || null,
        browserVersionMajor:
          +(device?.browser?.version || "0.0").split(".")[0] ||
          device?.browser?.version ||
          null,
        name: `${device?.os?.name || "Unknown"} ${
          device?.os?.version || "Unknown"
        }`,
        osName: device?.os?.name || null,
        osVersion: device?.os?.version || null,
        osVersionMajor:
          +(device?.os?.version || "0.0").split(".")[0] ||
          device?.os?.version ||
          null,
        osVersionName: device?.os?.versionName || device?.os?.version || null,
        platformType: device?.platform?.type,
        devicePixelRatio,
        resolutionWidth: window.screen.width * (devicePixelRatio || 1),
        resolutionHeight: window.screen.height * (devicePixelRatio || 1),
        width: window.screen.width,
        height: window.screen.height,
        orientation: window.orientation || 0,
        userAgent: window.navigator.userAgent,
        ip: this.isp?.ip || null,
        isp: this.isp,
        date: dayjs().format("YYYY-MM-DD"),
        timestamp: Date.now(),
      };
      result.id = `${this?.user?.email || 'unknown'}-${result.osName}-${result.osVersion}`;
      if (result.model) {
        result.id += `-${result.model}`;
        result.name = `${result.model} ${result.name}`;
      }
      if (result.vendor) {
        result.id += `-${result.vendor}`;
      }
      result.id += `-${result.ip}`;

      return result;
    },
    header() {
      let header = "Mobile";
      switch (this.device.platformType) {
        case "mobile":
          header = "Mobile";
          break;
        case "tablet":
          header = "Tablet";
          break;
      }
      return header;
    },
  },
  methods: {
    share() {
      if (navigator.share && this?.user?.email) {
        const shareObj = {
          title: "VIPdesk Home Office Assessment",
          to: this.user.email,
          url: "https://verify.vipdesk.com",
          text: `Thank you for your interest in VIPdesk. 
Please run this check (https://verify.vipdesk.com) on your home office computer. We need to test both your home office computer and internet connection. Once you are done and passed, please apply online at https://vipdesk.com/careers/current-openings.`,
        };

        navigator
          .share(shareObj)
          .then(() => {
            console.log("Thanks for sharing!");
          })
          .catch((err) => {
            console.log("not sharing", err);
          });
        // Web Share API is supported
      }
    },
  },
  async created() {
    this.isp = await getISP();

    saveDoc(this.device.id, this.device, "mobileDevice")
      .then(() => {
        this.saved = true;
      })
      .catch((error) => {
        this.saved = false;
        this.error = error;
        let payload = {
          id: `${this?.user?.email || 'unknown'}-${this.device?.date || 'unknown date'}-${
            this.device?.timestamp || 'unknown timestamp'
          }-${Math.round(Math.random() * 10000)}`,
          uid: this?.user?.uid || 'unknown uid',
          email: this?.user?.email || 'unknown email',
          code: error?.code || 'unknown code',
          name: error?.name || 'unknown name' ,
          error: error.toString(),
        };
        saveDoc(payload.id, payload, "error");
      });
  },
};
</script>

<style>
</style>